/**
 *
 * @param {*} number The number to round.
 * @param {*} [precision=0] The precision to round to.
 * @returns {number} Returns the rounded number.
 */
export const round = (number, precision) => {
    const validNumber = typeof number === 'number' ? number : 0;

    precision = precision == null ? 0 : precision >= 0 ? Math.min(precision, 292) : Math.max(precision, -292);

    if (precision) {
        let pair = `${validNumber}e`.split('e');
        const value = Math.round(`${pair[0]}e${Number(pair[1]) + precision}`);

        pair = `${value}e`.split('e');

        return Number(`${pair[0]}e${Number(pair[1]) - precision}`);
    }

    return Math.round(number);
};
