<template>
  <div class="h-full flex flex-col">
    <loader :loading="this.loading" :backdrop="true" />
    <div class="form-row">
      <div class="form-col">
          <label for="unit_number">unit number</label>
          <text-input type="number" v-model="unit.unit_name" id="unit_number" />
          <span v-if="hasError('unit.unit_name')" class="form-error">{{
            showError('unit.unit_name')
          }}</span>
      </div>
        <div class="form-col">
          <label for="starting_price">starting price</label>
          <AmountInput 
            v-model="unit.starting_price" 
            id="starting_price"
            prefix="$" 
            amountClass="form-input outline"
            :disabled="isSync"
          />
          <span v-if="hasError('unit.starting_price')" class="form-error">{{ showError('unit.starting_price') }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label for="available_date">available date x</label>
        <DatePicker
          name="available_date"
          :value="unit.available_date"
          @change="changeDates"
        >
        </DatePicker>
      </div>
      <div class="form-col">
          <label for="bedrooms">bedrooms</label>
          <text-input v-model="unit.floor_plan_bedroom_count" type="number" id="bedrooms" />
          <span v-if="hasError('unit.floor_plan_bedroom_count')" class="form-error">{{
            showError('unit.floor_plan_bedroom_count')
          }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
          <label for="public_floorplan_name">floorplan name</label>
          <text-input v-model="unit.floor_plan_name" id="public_floorplan_name" />
          <span v-if="hasError('unit.floor_plan_name')" class="form-error">{{
            showError('unit.floor_plan_name')
          }}</span>
      </div>
      <div class="form-col">
          <label for="bathrooms">bathrooms</label>
          <text-input type="number" v-model="unit.floor_plan_full_bath_count" id="bathrooms" />
          <span v-if="hasError('unit.floor_plan_full_bath_count')" class="form-error">{{
            showError('unit.floor_plan_full_bath_count')
          }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
          <label for="internal_floorplan_name">unit type</label>
          <text-input v-model="unit.floor_plan_type" id="internal_floorplan_name"  />
          <span v-if="hasError('unit.floor_plan_type')" class="form-error">{{
            showError('unit.floor_plan_type')
          }}</span>
      </div>
      <div class="form-col">
          <label for="square footage">square footage</label>
          <text-input type="number" v-model="unit.size_in_square_feet" id="square footage" />
          <span v-if="hasError('unit.size_in_square_feet')" class="form-error">{{
            showError('unit.size_in_square_feet')
          }}</span>
      </div>
    </div>
    <div class="form-row mt-4">
      <div class="form-col">
        <file-manager
            main-directory="floorplan"
            :max-upload-size="20"
            :max-nb-of-files="nbImages"
            :cropper-options="{aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false}"
            v-model="unit.floor_plan_image_link"
            :library="getLibraryImageFilesByBaseDirectory('floorplan')"
        ></file-manager>
        <label>add floorplan image</label>
      </div>
    </div>

    <modal-footer :footer="footer" :primary="save" :tertiary="cancel" ></modal-footer>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import DatePicker from "@/components/ui/datepicker/DatePicker";
import FileManager from "@/components/ui/filemanager/FileManager"
import TextInput from '@/components/ui/TextInput';
import AmountInput from "@/components/ui/AmountInput";
export default {
  mixins: [AuthMixin, NotifyMixin, ModalNavigation],
  components: {
    Loader,
    ModalFooter,
    DatePicker,
    FileManager,
    TextInput,
    AmountInput
  },
  data() {
    return {
      loading: false,
      nbImages: 1,
      unit: {
        available_date: null,
        available_for_movein: false,
        available_to_lease: false,
        community_id: '',
        customer_id: '',
        floor_plan_bedroom_count: 0,
        floor_plan_full_bath_count: 0,
        floor_plan_image_link: [],
        floor_plan_name: '',
        floor_plan_type: '',
        id_unit: '',
        size_in_square_feet: 0,
        starting_price: '',
        unit_name: ''
      },
      footer: {
          primaryButton: 'save',
          tertiaryButton: 'cancel',
      },
    }
  },
  validations: {
    'unit.floor_plan_bedroom_count': 'required | integer | only_positive_numbers',
    'unit.floor_plan_full_bath_count': 'required | integer | only_positive_numbers',
    'unit.floor_plan_type': 'required | is_empty',
    'unit.size_in_square_feet': 'required | integer | only_positive_numbers',
    'unit.starting_price': 'required | numeric',
    'unit.unit_name': 'required | only_positive_numbers',
    'unit.floor_plan_name': 'is_empty'
  },
  computed: {
    ...mapGetters({
        getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
        getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
        getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl',
        getUnitId: 'floorplan/getUnitId',
        getFloorplan: 'floorplan/getFloorplan'
    }),
  },
  methods: {
    ...mapActions({
      setFloorplanImgData: 'floorplan/setFloorplanImgData',
    }),
    changeDates(date) {
      this.unit.available_date = date;
    },
    loadUnit() {
      let sp = this.getFloorplan.starting_price || 0;
      if (isNaN(sp)) {
        sp = 0;
      } else if (sp) {
        sp = sp.toString().includes('$') ? sp.toString().slice(1) : sp;
      }
      this.unit = {
        ...this.getFloorplan,
        starting_price: Number(sp)
      };
    },
    save() {
      if (this.validator.passes()) {
        this.loading = true;
        const data = {
          floor_plan_image_link: this.unit.floor_plan_image_link.length ? this.getThumbnailUrlByFileId(this.unit.floor_plan_image_link[0]) : null,
          unit_name: this.unit.unit_name,
          floor_plan_type: this.unit.floor_plan_type,
          floor_plan_name: this.unit.floor_plan_name,
          size_in_square_feet: this.unit.size_in_square_feet,
          available_to_lease: this.unit.available_to_lease,
          available_for_movein: this.unit.available_for_movein,
          available_date: this.unit.available_date,
          floor_plan_bedroom_count: this.unit.floor_plan_bedroom_count,
          floor_plan_full_bath_count: this.unit.floor_plan_full_bath_count,
          starting_price: this.unit.starting_price,
          community_id: this.community?.id
        }
        this.$dhDataProvider.update('unit', {id: this.$route.params.id, data: data})
        .then(response => {
          if (response.status === 200){
            this.notifySuccess('the unit was updated successfully.')
            this.$router.push({name: 'availability_pricing.index'})
            this.loading = false
          }
        })
        .catch((error) => this.notifyError(error))
      }
    },
    cancel() {
      this.$router.push({name: 'availability_pricing.index'})
    },
  },
  beforeMount() {
    if (this.getFloorplan) this.loadUnit();
  },
  created() {
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    }
    this.initValidator();
    this.validator.extend('only_positive_numbers', function(value) {
      return (/^-?[\d.]+(?:e-?\d+)?$/.test(Number(value))) && Number(value) > 0;
    });
    this.validator.extend('is_empty', function(value) {
      return !value.match(/^\s+$/)
    })
    this.validator.setCustomErrorMessages({
        'unit.floor_plan_bedroom_count.only_positive_numbers' : 'enter a valid value.',
        'unit.floor_plan_full_bath_count.only_positive_numbers' : 'enter a valid value.',
        'unit.unit_name.only_positive_numbers': 'enter a valid value.',
        'unit.size_in_square_feet.only_positive_numbers' : 'enter a valid value.',
        'unit.floor_plan_type.is_empty': 'this field is empty.',
        'unit.floor_plan_name.is_empty': 'this field is empty.'
    })
  }
}
</script>
<style scoped>
.outline {
  outline: none !important;
}
</style>